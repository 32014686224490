<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.manager.managerListing')" :onSearch="onSearch">
                <template v-slot:headerLeft>
                    <button type="button" name="add-sales" class="btn btn-success" @click="onAddSalesman">Thêm Sales</button>
                </template>
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">
                                        {{ $t("pages.manager.salesManager") }}
                                    </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="saleManagerList"
                                                     v-model="selectedSaleManager"
                                                     :loading="isLoadingSaleManager"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     :internal-search="false"
                                                     @search-change="asyncFindSaleManager"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="6"></CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="salesmanList"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }">
                        <template #footer v-if="salesmanList != null && salesmanList.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="6">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{salesmanList == null ? 0: salesmanList.length}}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>
                        <template #createdDate_text="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                        <template #user_detail_actions="{item, index}">
                            <td class="py-2">
                                <!--<CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onEditSalesman(item, index)">
                                    {{ $t('common.edit') }}
                                </CButton>-->
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDeleteSalesman(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>                  
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'
    import { mapActions, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'

    export default {
        name: 'Tables',
        data() {
            return {
                saleManagerList: [],
                selectedSaleManager: null,
                isLoadingSaleManager: false,
                itemsPerPage: 10,
                warningModal: false,
                searchSalestName: '',
                fields: [
                    { key: 'salesName', label: i18n.t('pages.manager.salesName') },
                    { key: 'manager', label: i18n.t('pages.manager.manager') },                                      
                    { key: 'level', label: i18n.t('pages.manager.level') },
                    { key: 'createdDate_text', label: i18n.t('pages.warehouse.createdDate') },
                    {
                        key: 'user_detail_actions',
                        label:  i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },
        components: { Confirmation, ListForm },
        computed: {          
            ...mapState('salesman', ['isLoading', 'salesmanList', 'totalPages', 'pageIndex'])
        },
        methods: {
            ...mapActions('salesman', ['searchSalesmans']),
            formatDate(date) {
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },

            onDeleteSalesman(item) {
                this.$refs.confirmation.show(i18n.t('common.confirmDelete'), async () => {
                    var retVal = await this.$store.dispatch("salesman/deleteSalesman", item.id);
                    if (retVal == true) {
                        this.onSearch();
                    }
                });
            },
            onEditSalesman(item) {
                this.$router.push('/salesman/edit/' + item.id);
            },
            onSearch() {
                let _managerIds = [];

                if (this.selectedSaleManager != null && this.selectedSaleManager.length > 0)
                    _managerIds = this.selectedSaleManager.map(item => item.id);
                this.searchSalesmans({ pageNumber: 0, managerIds: _managerIds });
            },           
            onAddSalesman() {
                this.$router.push('/salesman/add');
            },
            onItemsPerPageChange(item) {
                localStorage.setItem('salesmanItemsPerPage', item)
            },

            async asyncFindSaleManager(query, id) {
                if (query.length > 1) {
                    this.isLoadingSaleManager = true;
                    var result = await this.$salesmanService.searchByName(query);
                    this.isLoadingSaleManager = false;
                    this.saleManagerList = result;
                }
            },
        },
        created() {
            this.onSearch();

            let salesmanItemsPerPage = parseInt(localStorage.getItem('salesmanItemsPerPage'));
            if (isNaN(salesmanItemsPerPage))
                salesmanItemsPerPage = 10;
            this.itemsPerPage = salesmanItemsPerPage;
        }     
    }
</script>