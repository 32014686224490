var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ListForm',{attrs:{"headerTitle":_vm.$t('pages.manager.managerListing'),"onSearch":_vm.onSearch},scopedSlots:_vm._u([{key:"headerLeft",fn:function(){return [_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","name":"add-sales"},on:{"click":_vm.onAddSalesman}},[_vm._v("Thêm Sales")])]},proxy:true},{key:"searchBox",fn:function(){return [_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group form-row",attrs:{"role":"group"}},[_c('label',{staticClass:"col-form-label col-sm-3"},[_vm._v(" "+_vm._s(_vm.$t("pages.manager.salesManager"))+" ")]),_c('div',{staticClass:"col-sm-9"},[_c('multiselect',{attrs:{"options":_vm.saleManagerList,"loading":_vm.isLoadingSaleManager,"searchable":true,"close-on-select":true,"clear-on-select":false,"multiple":true,"placeholder":_vm.$t('common.typetosearch'),"noOptionsText":_vm.$t('common.theListIsEmpty'),"reset-after":false,"local-search":false,"internal-search":false,"id":"ajax","label":"name","track-by":"id"},on:{"search-change":_vm.asyncFindSaleManager},model:{value:(_vm.selectedSaleManager),callback:function ($$v) {_vm.selectedSaleManager=$$v},expression:"selectedSaleManager"}})],1)])]),_c('CCol',{attrs:{"md":"6"}})],1)],1)]},proxy:true},{key:"list",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.salesmanList,"fields":_vm.fields,"noItemsView":{ noItems: _vm.$t('common.nodatafound') },"hover":"","striped":"","border":"","small":"","fixed":"","loading":_vm.isLoading,"pagination":"","items-per-page":_vm.itemsPerPage,"items-per-page-select":{
                            label : _vm.$t('common.recordsperpage'),
                            values : [10,20,50,100]
                            }},on:{"pagination-change":_vm.onItemsPerPageChange},scopedSlots:_vm._u([(_vm.salesmanList != null && _vm.salesmanList.length > 0)?{key:"footer",fn:function(){return [_c('tfoot',[_c('tr',[_c('td',{attrs:{"colspan":6}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.totalRecords'))+": ")]),_c('span',{staticStyle:{"padding-left":"5px","color":"crimson","font-weight":"bold"}},[_vm._v(_vm._s(_vm.salesmanList == null ? 0: _vm.salesmanList.length))])])])])]},proxy:true}:null,{key:"createdDate_text",fn:function(ref){
                            var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('label',{domProps:{"textContent":_vm._s(_vm.formatDate(item.createdDate))}})])]}},{key:"user_detail_actions",fn:function(ref){
                            var item = ref.item;
                            var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onDeleteSalesman(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.remove'))+" ")])],1)]}}],null,true)})]},proxy:true}])}),_c('Confirmation',{ref:"confirmation"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }